import React from 'react'
import { Link } from 'react-router-dom'

const BreadcrumbContainer = (props) => {
  return (
    <section className="page-title-section2 bg-img cover-background top-position1"  style={{backgroundImage:'url("/assets/img/pagetitle.jpg")'}} data-overlay-dark="6">
        <div className="container">

            <div className="row">
                <div className="col-md-12">
                    <h1>{props.title}</h1>
                </div>
                <div className="col-md-12">
                    <ul className="ps-0">
                        <li className='px-2'><Link to={'/'}>Home</Link></li>
                        <li><Link>{props.title}</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
  )
}

export default BreadcrumbContainer
