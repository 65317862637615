import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoMdCall } from 'react-icons/io'
import { IoLocationOutline, IoMailOpenOutline } from 'react-icons/io5'
import { MdAccessTime } from 'react-icons/md'
export const ConatctContainer = () => {
  return (
    <>
        <Container fluid  className='pt-4 bg-dark-grey' data-aos="fade-up">
            <Row>
                <Col>
                    <h2 className='our-topers liner-text2 mt-5 mb-5'>Contact Us</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Container>
                        <div class="row">
                            <div class="col-lg-5 pe-lg-5 aos-init aos-animate" data-aos="fade-right">
                                <div class="contact-area p-5 border rounded-3 bg-light-dark">                                  
                                    <div class="email">
                                        <div class="row">
                                        <div class="col-2 text-center">                                            
                                            <IoMailOpenOutline size={'30'} className='text-danger' />                                 
                                        </div>
                                        <div class="col-10">
                                            <p class="f-20 font-weight-bold">Email:</p>
                                            <p class="mb-0">rrpsmohanlalganj@gmail.com</p>
                                        </div>
                                        </div>
                                    </div>                                   
                                    <div class="phone">
                                        <div class="row">
                                        <div class="col-2 text-center">                                          
                                            <IoMdCall size={'30'} className='text-danger' />                                 
                                        </div>
                                        <div class="col-10">
                                            <p class="f-20 font-weight-bold">Call:</p>
                                            <p class="mb-0">+91-809 090 2463</p>
                                        </div>
                                        </div>
                                    </div>                                    
                                    <div class="time">
                                        <div class="row">
                                        <div class="col-2 text-center">                                          
                                            <MdAccessTime size={'30'} className='text-danger' />                                   
                                        </div>
                                        <div class="col-10">
                                            <p class="f-20 font-weight-bold">Working Hours:</p>
                                            <p class="mb-0">08:00 AM to 04:00 PM</p>
                                        </div>
                                        </div>
                                    </div>
                                 
                                    <div class="address">
                                        <div class="row">
                                        <div class="col-2 text-center">                                            
                                            <IoLocationOutline size={'30'} className='text-danger' />                                   
                                        </div>
                                        <div class="col-10">
                                            <p class="f-20 font-weight-bold">Location:</p>
                                            <p class="mb-0">Vill- Bhajan Mau, Post- Dayalpur, Tehsil- Mohanlalganj, Lucknow 226301</p>
                                        </div>
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                            <div class="col-lg-7 ps-lg-0 mt-5 mt-lg-0 aos-init aos-animate" data-aos="fade-left">
                                <div class="w-100 p-5 border rounded-3 bg-light-dark form-content">                                  
                                    <form action="#" class="contact-form">
                                        <div class="row">
                                            <div class="col-md-6 mb-4">
                                                <label class="form-label" for="name">Your Name</label>
                                                <input type="text" name="name" class="form-control" id="name" />
                                                <div class="validate"></div>
                                            </div>
                                            <div class="col-md-6 mb-4">
                                                <label class="form-label" for="email">Your Email</label>
                                                <input type="email" class="form-control" name="email" id="email" />
                                                <div class="validate"></div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6 mb-4">
                                                <label class="form-label" for="name">Contact No.</label>
                                                <input type="text" name="name" class="form-control" id="name" />
                                                <div class="validate"></div>
                                            </div>
                                            <div class="col-md-6 mb-4">
                                                <label class="form-label" for="subject">Subject</label>
                                                <input type="text" class="form-control" name="subject" id="subject" />
                                                <div class="validate"></div>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <label class="form-label" for="messages">Message</label>
                                            <textarea class="form-control" name="message" rows="5" id="messages"></textarea>
                                            <div class="validate"></div>
                                        </div>
                                        <div class="text-center mb-0 pb-0">                                       
                                            <button type="button" class="liner-button btn btn-primary">Send Message</button>
                                        </div>
                                    </form>                                   
                                </div>
                            </div>
                            </div>
                    </Container>
                </Col>
            </Row>

            <Row>
                <Col className='col-md-12'>
                    <div class="responsive-maps">
                        <iframe class="lazy entered loaded" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14259.098346531784!2d80.9900566!3d26.6876908!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bef59df157c7b%3A0xd9fd411fff6a58d9!2sRed%20Rose%20Public%20School!5e0!3m2!1sen!2sin!4v1724083230527!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </Col>
            </Row>
      </Container>
    </>
  )
}
