import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import ParentsContainer from './ParentsSection'
import StudentContainer from './StudentSection'


const TestimonialContainer = () => {
  return (
    <>
      <Container fluid  className='pt-4 pb-5 testmonial position-relative bg-secondary-2' data-aos="fade-up">
            {/* <div class="line-shape wow fadeIn">
                <img src="/assets/img/bg/process-line.png" alt="..." />
            </div> */}
            <img src="/assets/img/bg/about3.png" class="position-absolute bottom-0 left" alt="..." />
            <Row>
                <Col>
                    <h2 className='our-topers liner-text3 mt-5 text-white'>Testimonials</h2>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col>
                    <Container>
                        <Row>
                            <div className='col-md-6'>       
                                <ParentsContainer />
                            </div>
                            <div className='col-md-6'>    
                                <StudentContainer />         
                            </div>
                        </Row>
                    </Container>
                </Col>
            </Row>
      </Container>
    </>
  )
}

export default TestimonialContainer
