import React from 'react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ManagementContainer = () => {
    const slider = [
        {id:1, smalldis: 'Shri R.C. Mishra', dis: 'Founder', img: '/assets/img/team/founders-01.png'},
        {id:2, smalldis: 'Late Smt. Shashi Mishra', dis: 'Founder', img: '/assets/img/team/founders-02.png'},
        {id:3, smalldis: 'Dr. Prashant Kumar Mishra', dis: 'Managing Director', img: '/assets/img/team/md.png'},
        {id:4, smalldis: 'Smt. Smita Mishra', dis: 'Chairperson', img: '/assets/img/team/president.png'},
    ];  
  return (
    <>
      <Container fluid  className='pt-4 pb-5 bg-dark-grey managements-section' data-aos="fade-up">
            <Row>
                <Col>
                    <Container>
                        <Row>
                            <Col>
                                <h2 className='our-topers liner-text2 mt-5'>Management</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <section className='mt-5 pb-5'>                               
                                <Swiper 
                                    spaceBetween={0}                                      
                                    breakpoints={{640: {slidesPerView: 2, spaceBetween: 20,}, 
                                        768: {slidesPerView:1, spaceBetween: 30,},
                                        1024: {slidesPerView: 4, spaceBetween:20,},
                                    }} 
                                    effect="fade" 
                                    loop={true} 
                                    pagination={{ clickable: true }} 
                                    autoplay={{delay:5000, 
                                    disableOnInteraction: false,}} 
                                    modules={[Autoplay, Navigation, Pagination]} className='mgn'>
                                    {slider.map(item => (<SwiperSlide>      
                                        <Container className="position-relative w-100">
                                            <Row>                                                
                                                <Col className='col-md-12 pb-4'>
                                                    <div class="card rounded aos-init aos-animate mng" data-aos="fade-up">
                                                        <Image src={item.img} class="img-fluid rounded-top" alt="" />
                                                        <div class="bg-white rounded-bottom p-3 text-center">
                                                            <h4 class="title fw-bold text-dark text-center mb-0">Portfolio</h4>
                                                            <p className='mb-1'>Project Manager</p>   
                                                            <p><Link to={'#'} className='liner-button btn btn-primary'>Read more</Link></p>                                                        
                                                        </div>                                                        
                                                    </div>  
                                                </Col>
                                            </Row>                     
                                        </Container>           
                                    </SwiperSlide>
                                    ))}   
                                </Swiper>
                            </section>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>      
    </>
  )
}

export default ManagementContainer
