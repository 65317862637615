import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const OurBranchContainer = () => {
    const impfact = [
        {id:1, smalldis: 'Kanpur Road', dis: 'Vishnulok Colony, Kanpur Road, Lucknow ', cont: '+91-9956208333', cont2: '+91-9951644440'},
        {id:2, smalldis: 'Rajajipuram', dis: 'D-Block, Rajajipuram, Lucknow', cont: '+91-8601500307', cont2: '+91-8840183326'},
        {id:3, smalldis: 'Gomti Nagar', dis: 'Vinay Khand-3, Gomti Nagar, Lucknow', cont: '+91-8840638501', cont2: '+91-7311170771'},
        {id:4, smalldis: 'Mohanlalganj', dis: 'Bhajanmau, Mohanlalganj, Lucknow', cont: '+91-8090902463', cont2: '+91-9651644490'},
    ];  
  return (
    <>
      <Container fluid  className='pt-4 pb-5' data-aos="fade-up">
            <Row>
                <Col>
                    <Container className="almuni-section">
                        <Row>
                            <Col>
                                <h2 className='our-topers liner-text2 mt-5'>Our Branches</h2>
                            </Col>
                        </Row>
                        <Row className='pt-5'>
                        {impfact.map(item => (<div className='col-md-3 pb-4'>
                                <div className='fals-section d-flex justify-content-center align-items-center'>                                   
                                    <div className='text-center'>
                                        <h2 className='f-24 font-weight-bold pb-1'>
                                            {item.smalldis}
                                        </h2>
                                        <p className='px-2 mb-0'>{item.dis}</p>
                                        <p className='mb-0'>{item.cont}</p>
                                        <p>{item.cont2}</p>
                                    </div>
                                </div>
                            </div>
                            ))}  
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>

    </>
  )
}

export default OurBranchContainer



