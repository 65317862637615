import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const AnimatedCounter = ({ start = 0, end = 1000, duration = 2000 }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCount(Math.floor(progress * (end - start) + start));

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  }, [start, end, duration]);

  return (
    <div>
      {count}
    </div>
  );
};

const Counter = () => (  
  <>
     <Container fluid  className='pt-4 pb-5 bg-dark-grey' data-aos="fade-up">
            <Row>
                <Col>
                    <Container className="almuni-section">
                        <Row>
                            <Col>
                                <h2 className='our-topers liner-text2 mt-5'>Important Facts</h2>
                            </Col>
                        </Row>
                        <Row className='pt-5'>
                            <Col className='col-md-3 pb-4'>
                                <div className='fals-section d-flex gap-3 align-items-center'>
                                    <div><img src="/assets/img/counter-icon_1.png" title='3' alt='' /></div>
                                    <div>
                                        <h2 className='f-28 font-weight-bold mb-0'>
                                            <AnimatedCounter start={0} end={4500}  duration={3000}>+</AnimatedCounter>
                                        </h2>
                                        <p>Student</p>
                                    </div>
                                </div>
                            </Col>

                            <Col className='col-md-3 pb-4'>
                                <div className='fals-section d-flex gap-3 align-items-center'>
                                    <div><img src="/assets/img/counter-icon_2.png" title='3' alt='' /></div>
                                    <div>
                                        <h2 className='f-28 font-weight-bold mb-0'>
                                            <AnimatedCounter start={0} end={5} duration={3000} >+</AnimatedCounter>
                                        </h2>
                                        <p>Branches</p>
                                    </div>
                                </div>
                            </Col>

                            <Col className='col-md-3 pb-4'>
                                <div className='fals-section d-flex gap-3 align-items-center'>
                                    <div><img src="/assets/img/counter-icon_3.png" title='3' alt='' /></div>
                                    <div>
                                        <h2 className='f-28 font-weight-bold mb-0'>
                                            <AnimatedCounter start={0} end={350} duration={3000} >+</AnimatedCounter>
                                        </h2>
                                        <p>Staff</p>
                                    </div>
                                </div>
                            </Col>

                            <Col className='col-md-3 pb-4'>
                                <div className='fals-section d-flex gap-3 align-items-center'>
                                    <div><img src="/assets/img/counter-icon_4.png" title='3' alt='' /></div>
                                    <div>
                                        <h2 className='f-28 font-weight-bold mb-0'>
                                            <AnimatedCounter start={0} end={100} duration={3000} >+</AnimatedCounter>
                                        </h2>
                                        <p>Almuni Review</p>
                                    </div>
                                </div>
                            </Col>
                           
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    
  </>
);

export default Counter;





