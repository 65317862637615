import React from 'react'
import BreadcrumbContainer from '../Common/Breadcrumb'

const AdmissionProcedure = () => {
  return (
    <>
      <BreadcrumbContainer title="Admission Procedure" />
        <section className="position-relative about-section"  data-aos="fade-up">
            <div className="container">
                <div className='row'>
                    <div className='col-md-3'>
                        <div class="sidebar">                            
                            <div class="widget">
                                <div class="widget-title">
                                    <h3>Admission</h3>
                                </div>
                                <ul class="category-list">
                                    <li><a href="#!">Admission Procedure</a></li>
                                    <li><a href="#!">General Rules</a></li>
                                    <li><a href="#!">Fee Structure</a></li>
                                    <li><a href="#!">Download Prospectus</a></li>
                                    <li><a href="#!">Planner</a></li>
                                    <li><a href="#!">Safety & Security</a></li>
                                    <li><a href="#!">NCC</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-9'>
                        <div className="pe-lg-1-9 pe-xl-6 px-4">
                            <div className="section-heading half left">
                                <h2>About Red Rose</h2>
                            </div>
                            <h4 className="pt-4 pb-2">School Vision</h4>
                            <ul className="list-style-16">
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            </ul>
                            <h4 className="pt-4 pb-2">School Mission</h4>
                            <ul className="list-style-16">
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AdmissionProcedure
