import React from 'react'
import BreadcrumbContainer from '../Common/Breadcrumb'
import { Link } from 'react-router-dom'
import { FaPhoneAlt } from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'

const Branches = () => {
  return (
    <>
   
    <BreadcrumbContainer  title="Our Branches" />
      <section class="pt-5">
            <div class="container">
                <div class="row g-4 justify-content-center mb-5">  
                    <div class="col-lg-10 col-xl-6">
                        <div class="card shadow p-2">
                            <div class="row g-0">                           
                                <div class="col-md-4">
                                    <img src="assets/img/banner/school-01.png" class="rounded-3" alt="..." />
                                </div>                           
                                <div class="col-md-8">
                                    <div class="card-body">                                   
                                        <div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                                            <div>
                                                <h5 class="card-title mb-1"><Link>Kanpur Road</Link></h5>
                                                <p class="small mb-2 mb-sm-0">Vishnulok Colony, Kanpur Road, Lucknow</p>
                                            </div>                                  
                                        </div>
                                    
                                        <div class="text-truncate-2 mb-3 d-flex gap-2">
                                            <span><FaPhoneAlt /> +91-9956208333, +91-9951644440</span>
                                        </div>
                                        <div class="text-truncate-2 mb-3 d-flex gap-2">
                                            <span><IoIosMail /> <Link   as={Link} to="mailto:info@redrosepublicschool.com">info@redrosepublicschool.com</Link> </span>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                            <div class="row g-0">                           
                                <div class="col-md-12">
                                    <div class="responsive-maps2">
                                        <iframe class="lazy entered loaded" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14259.098346531784!2d80.9900566!3d26.6876908!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bef59df157c7b%3A0xd9fd411fff6a58d9!2sRed%20Rose%20Public%20School!5e0!3m2!1sen!2sin!4v1724083230527!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                 
                    <div class="col-lg-10 col-xl-6">
                        <div class="card shadow p-2">
                            <div class="row g-0">                           
                                <div class="col-md-4">
                                    <img src="assets/img/banner/school-02.png" class="rounded-3" alt="..." />
                                </div>                           
                                <div class="col-md-8">
                                    <div class="card-body">                                   
                                        <div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                                            <div>
                                                <h5 class="card-title mb-1"><Link>Rajajipuram</Link></h5>
                                                <p class="small mb-2 mb-sm-0">D-Block, Rajajipuram, Lucknow</p>
                                            </div>                                  
                                        </div>
                                    
                                        <div class="text-truncate-2 mb-3 d-flex gap-2">
                                            <span><FaPhoneAlt /> +91-8601500307, +91-8840183326</span>
                                        </div>
                                        <div class="text-truncate-2 mb-3 d-flex gap-2">
                                            <span><IoIosMail /> <Link   as={Link} to="mailto:info@redrosepublicschool.com">info@redrosepublicschool.com</Link> </span>
                                        </div>                                      
                                    </div>
                                </div>
                            </div>

                            <div class="row g-0">                           
                                <div class="col-md-12">
                                    <div class="responsive-maps2">
                                        <iframe class="lazy entered loaded" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14259.098346531784!2d80.9900566!3d26.6876908!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bef59df157c7b%3A0xd9fd411fff6a58d9!2sRed%20Rose%20Public%20School!5e0!3m2!1sen!2sin!4v1724083230527!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  

                    <div class="col-lg-10 col-xl-6">
                        <div class="card shadow p-2">
                            <div class="row g-0">                           
                                <div class="col-md-4">
                                    <img src="assets/img/banner/school-03.png" class="rounded-3" alt="..." />
                                </div>                           
                                <div class="col-md-8">
                                    <div class="card-body">                                   
                                        <div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                                            <div>
                                                <h5 class="card-title mb-1"><Link>Gomti Nagar</Link></h5>
                                                <p class="small mb-2 mb-sm-0">Vinay Khand-3, Gomti Nagar, Lucknow</p>
                                            </div>                                  
                                        </div>
                                    
                                        <div class="text-truncate-2 mb-3 d-flex gap-2">
                                            <span><FaPhoneAlt /> +91-8840638501, +91-7311170771</span>
                                        </div>
                                        <div class="text-truncate-2 mb-3 d-flex gap-2">
                                            <span><IoIosMail /> <Link   as={Link} to="mailto:info@redrosepublicschool.com">info@redrosepublicschool.com</Link> </span>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>

                            <div class="row g-0">                           
                                <div class="col-md-12">
                                    <div class="responsive-maps2">
                                        <iframe class="lazy entered loaded" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14259.098346531784!2d80.9900566!3d26.6876908!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bef59df157c7b%3A0xd9fd411fff6a58d9!2sRed%20Rose%20Public%20School!5e0!3m2!1sen!2sin!4v1724083230527!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-10 col-xl-6">
                        <div class="card shadow p-2">
                            <div class="row g-0">                           
                                <div class="col-md-4">
                                    <img src="assets/img/banner/school-04.png" class="rounded-3" alt="..." />
                                </div>                           
                                <div class="col-md-8">
                                    <div class="card-body">                                   
                                        <div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                                            <div>
                                                <h5 class="card-title mb-1"><Link>Mohanlalganj</Link></h5>
                                                <p class="small mb-2 mb-sm-0">Bhajanmau, Mohanlalganj, Lucknow</p>
                                            </div>                                  
                                        </div>
                                    
                                        <div class="text-truncate-2 mb-3 d-flex gap-2">
                                            <span><FaPhoneAlt /> +91-8090902463, +91-9651644490</span>
                                        </div>
                                        <div class="text-truncate-2 mb-3 d-flex gap-2">
                                            <span><IoIosMail /> <Link   as={Link} to="mailto:info@redrosepublicschool.com">info@redrosepublicschool.com</Link> </span>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>

                            <div class="row g-0">                           
                                <div class="col-md-12">
                                    <div class="responsive-maps2">
                                        <iframe class="lazy entered loaded" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14259.098346531784!2d80.9900566!3d26.6876908!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bef59df157c7b%3A0xd9fd411fff6a58d9!2sRed%20Rose%20Public%20School!5e0!3m2!1sen!2sin!4v1724083230527!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>            
                </div>               
            </div>
            </section>
    </>
  )
}

export default Branches
