import React from 'react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Button, Image } from 'react-bootstrap';

export default function HeroSliderContainer() {
    const slider = [
        {id:1, smalldis: 'Welcome to Red Rose School', dis: 'Empowering students to excel', img: '/assets/img/banner/seo.jpg'},
        {id:2, smalldis: 'Welcome to Red Rose School', dis: 'Empowering students to excel', img: '/assets/img/banner/web.jpg'},
        {id:3, smalldis: 'Welcome to Red Rose School', dis: 'Empowering students to excel', img: '/assets/img/banner/slider.png'},
    ];

  return (
    <>
        <Swiper className='hero-section' spaceBetween={0} slidesPerView={1} effect="fade" loop={true} navigation pagination={{ clickable: true }} autoplay={{delay: 3000, disableOnInteraction: false,}} modules={[Autoplay, Navigation, Pagination]}>
            {slider.map(item => (<SwiperSlide>
                <div className="position-relative w-100 h-calc">
                        <Image src={item.img} alt="Banner Image" layout="fill" objectFit="cover" />
                        <div className='container'>
                            <div className="absolute-content text-white">                            
                                <div>
                                    <p className='mb-2 slider-small-heading'>{item.smalldis}</p>
                                    <h1 className='slider-heading liner-text'>{item.dis}</h1>
                                    <div className='mt-3'>
                                        <Button className='btn btn-info liner-button'>Read More</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </SwiperSlide>
        ))}   
      </Swiper>

      {/* <section className="bg-danger py-2">
            <div className="position-relative z-index-2">
                <div className="scrolling-text scrolling-left-text">
                    <div className="scrolling-marquee">
                        <h5 className="ms-2 display-1 font-weight-800"> Design &amp; Branding — Web Development — Product Design — </h5>
                        <h5 className="ms-2 display-1 font-weight-800 ms-2"> Mobile Application — Web Application — SEO Optimization - </h5>
                    </div>
                </div>
            </div>
        </section> */}
    </>
  )
}
