import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Layout from './Layout';
import About from './Pages/About';
import AdmissionProcedure from './Pages/AdmissionProcedure';
import ManagementMessage from './Pages/ManagementMessage';
import Contact from './Pages/Contact';
import Infrastructure from './Pages/Infrastructure';
import Branches from './Pages/Branches';
import Disclosure from './Pages/Disclosure';
import Classroom from './Pages/Classroom';
import ComputerLab from './Pages/ComputerLab';
import ScienceLab from './Pages/ScienceLab';
import MathsLab from './Pages/MathsLab';
import Library from './Pages/Library';
import ActivityRoom from './Pages/ActivityRoom';
import Music from './Pages/Music';
import DigitalClassrooms from './Pages/DigitalClassrooms';
import SwimmingPool from './Pages/SwimmingPool';
const Routing = () => {
  return (
    <BrowserRouter>          
        <Routes>            
            <Route path="/" exact element={<Layout />}>
                <Route index element={<Home />} />  
                <Route path='/About' element={<About />} />       
                <Route path='/AdmissionProcedure' element={<AdmissionProcedure />} />         
                <Route path='/ManagementMessage' element={<ManagementMessage />}></Route>
                <Route path='/Contact' element={<Contact />}></Route>
                <Route path='/Infrastructure' element={<Infrastructure />}></Route>
                <Route path='/Branches' element={<Branches />}></Route>
                <Route path='/Disclosure' element={<Disclosure />}></Route>
                <Route path='/Classroom' element={<Classroom />}></Route>
                <Route path='/ComputerLab' element={<ComputerLab />}></Route>
                <Route path='/ScienceLab' element={<ScienceLab />}></Route>
                <Route path='/MathsLab' element={<MathsLab />}></Route>
                <Route path='/Library' element={<Library />}></Route>
                <Route path='/ActivityRoom' element={<ActivityRoom />}></Route>
                <Route path='/Music' element={<Music />}></Route>
                <Route path='/DigitalClassrooms' element={<DigitalClassrooms />}></Route>
                <Route path='/SwimmingPool' element={<SwimmingPool />}></Route>
            </Route>
        </Routes>
    </BrowserRouter>
  )
}

export default Routing
