import React, { useState } from 'react'
import {Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
const AboutContainer = () => {
    const [isPaused, setIsPaused] = useState(false);
  return (
    <>
        <Container fluid className='pt-4 py-5' data-aos="fade-up">
            <Row>
                <Col>
                    <Container className='pt-3'>
                        <Row>
                            <div className='col-md-6 col-sm-12'>
                                <div className='mh-200 pr-100'>
                                    <h2 className='f-32 liner-text2 fw-bold pb-2'>About Red Rose</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</p>
                                </div>
                                <div className='d-flex justify-content-start mt-4'>
                                    <Button className='liner-button'>Read More</Button>
                                </div> 
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <h2 className='f-32 liner-text2 fw-bold pb-2 pt-30'>News Update</h2>
                                <div className={`list-sec mh-200 vertical-marquee-container ${isPaused ? "paused" : ""}`} onMouseEnter={() => setIsPaused(true)}  onMouseLeave={() => setIsPaused(false)}>
                                    <ul className='vertical-marquee pr-4'>
                                        <li className=''>Admissions open for the session 2024-2025. No Admission Fee for students registering till 30th June 2024.</li>   
                                        <li className=''>Download Stationery List for the Session 2024-2025.</li>                                                                       
                                    </ul>
                                </div>   
                                <div className='d-flex justify-content-start mt-4'>
                                    <Button className='liner-button'>Read More</Button>
                                </div>                             
                            </div>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>     

    </>
  )
}

export default AboutContainer
