import React from 'react'
import BreadcrumbContainer from '../Common/Breadcrumb'

const Disclosure = () => {
  return (
    <>
        <BreadcrumbContainer title="Mandatory Public Disclosure" />
      <section className='mt-4'>
        <div className='container'>
            <div class="row">
                <div class="service-content pb-3 text-center">
                    <h4 className='fw-bold'>APPENDIX - IX</h4> 
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-mar-top">
                    <div class=" myjustify my-mar-lrb">
                        <div class="service-content py-4">
                            <h6 className='fw-bold'>A. GENERAL INFORMATION:</h6>                             
                        </div>
                        <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="warning">
                                    <th>S.NO.</th>
                                    <th>INFORMATION</th>
                                    <th>DETAILS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>NAME OF THE SCHOOL</td>
                                    <td>RED ROSE PUBLIC SCHOOL</td>
                                </tr>
                                <tr class="success">
                                    <td>2</td>
                                    <td>AFFILIATION NO. (IF APPLICABLE)</td>
                                    <td>2130822</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>SCHOOL CODE (IF APPLICABLE)</td>
                                    <td>70935</td>
                                </tr>
                                <tr class="success">
                                    <td>4</td>
                                    <td>COMPLETE ADDRESS WITH PIN CODE</td>
                                    <td>NAV CHETNA KENDRA VISHNU LOK COLONYKANPUR ROAD LUCKNOW 226023</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>PRINCIPAL NAME</td>
                                    <td>MRS. VERSHA PANDEY</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>PRINCIPAL QUALIFICATION</td>
                                    <td>M.A., B.Ed.</td>
                                </tr>
                                <tr class="success">
                                    <td>7</td>
                                    <td>SCHOOL EMAIL ID</td>
                                    <td>info@redrosepublicschool.com</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>CONTACT DETAILS (LANDLINE/ MOBILE)</td>
                                    <td>9956208333</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div class="service-content py-3">
                            <h6 className='fw-bold'>B. DOCUMENTS AND INFORMATION:</h6>  
                        </div>
                        <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="warning">
                                    <th>S.NO.</th>
                                    <th>DOCUMENTS/ INFORMATION</th>
                                    <th>DOCUMENTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>COPIES OF AFFILIATION/ UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY</td>
                                    <td><a href="docs/pdf/B1.pdf" target="_blank">VIEW</a></td>
                                </tr>
                                <tr class="success">
                                    <td>2</td>
                                    <td>COPIES OF SOCIETIES/  TRUST/ COMPANY REGISTRATION/ RENEWAL CERTIFICATE, AS APPLICABLE</td>
                                    <td><a href="docs/pdf/B2.pdf" target="_blank">VIEW</a></td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE BY THE STATE GOVT/ UT</td>
                                    <td><a href="docs/pdf/B3.pdf" target="_blank">VIEW</a></td>
                                </tr>
                                <tr class="success">
                                    <td>4</td>
                                    <td>COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT 2009, AND IT’S RENEWAL IF APPLICABLE</td>
                                    <td><a href="docs/pdf/B4.pdf" target="_blank">VIEW</a></td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE</td>
                                    <td><a href="docs/pdf/B5.pdf" target="_blank">VIEW</a></td>
                                </tr>
                                <tr class="success">
                                    <td>6</td>
                                    <td>COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY</td>
                                    <td><a href="docs/pdf/B6.pdf" target="_blank">VIEW</a></td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/ UPGRADATION / EXTENSION OF AFFILIATION SELF CERTIFICATION BY SCHOOL</td>
                                    <td><a href="docs/pdf/B7.pdf" target="_blank">VIEW</a></td>
                                </tr>
                                <tr class="success">
                                    <td>8</td>
                                    <td>COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES</td>
                                    <td><a href="docs/pdf/B8.pdf" target="_blank">VIEW</a></td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div class="service-content py-3">
                            <h6 className='fw-bold'>C. RESULT AND ACADEMICS:</h6>  
                        </div>
                       
                        <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="warning">
                                    <th>S.NO.</th>
                                    <th>DOCUMENTS/ INFORMATION</th>
                                    <th>DOCUMENTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>FEE STRUCTURE OF THE SCHOOL</td>
                                    <td><a href="docs/pdf/C1.pdf" target="_blank">VIEW</a></td>
                                </tr>
                                <tr class="success">
                                    <td>2</td>
                                    <td>ANNUAL ACADEMIC CALENDAR</td>
                                    <td><a href="docs/pdf/C2.pdf" target="_blank">VIEW</a></td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)</td>
                                    <td><a href="docs/pdf/C3.pdf" target="_blank">VIEW</a></td>
                                </tr>
                                <tr class="success">
                                    <td>4</td>
                                    <td>LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS</td>
                                    <td><a href="docs/pdf/C4.pdf" target="_blank">VIEW</a></td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>LAST THREE – YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY</td>
                                    <td><a href="docs/pdf/C5.pdf" target="_blank">VIEW</a></td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div class="service-content py-3">
                            <h6 className='fw-bold'>D. STAFF (TEACHING):</h6>  
                        </div>
                       
                        <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="warning">
                                    <th>S.NO.</th>
                                    <th>INFORMATION</th>
                                    <th>DETAILS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>PRINCIPAL</td>
                                    <td>MRS. VERSHA PANDEY</td>
                                </tr>
                                <tr class="success">
                                    <td>2</td>
                                    <td>
                                        <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td className='text-start'>TOTAL NO. OF TEACHERS</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>PGT</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>TGT</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start'>PRT</td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </td>
                                    <td>
                                        <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>54</td>
                                            </tr>
                                            <tr>
                                                <td>19</td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                            </tr>
                                            <tr>
                                                <td>20</td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>TEACHERS SECTION RATIO</td>
                                    <td>40:1</td>
                                </tr>
                                <tr class="success">
                                    <td>4</td>
                                    <td>DETAILS OF SPECIAL EDUCATOR</td>
                                    <td>NA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>DETAILS OF COUNSELOR AND WELLNESS TEACHER</td>
                                    <td>1</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div class="service-content py-3 text-center">
                            <h4 className='fw-bold'>RESULT CLASS: X</h4>  
                        </div>
                       
                        <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="warning">
                                    <th>S.NO.</th>
                                    <th>YEAR</th>
                                    <th>NO. OF REGISTERED STUDENTS</th>
                                    <th>NO. OF STUDENTS PASSED</th>
                                    <th>PASS PERCENTAGE</th>
                                    <th>REMARKS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>2024</td>
                                    <td>89</td>
                                    <td>88</td>
                                    <td>99%</td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div class="service-content py-3 text-center">
                            <h4 className='fw-bold'>RESULT CLASS: XII</h4>  
                        </div>
                       
                        <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="warning">
                                    <th>S.NO.</th>
                                    <th>YEAR</th>
                                    <th>NO. OF REGISTERED STUDENTS</th>
                                    <th>NO. OF STUDENTS PASSED</th>
                                    <th>PASS PERCENTAGE</th>
                                    <th>REMARKS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>2024</td>
                                    <td>100</td>
                                    <td>98</td>
                                    <td>98%</td>
                                    <td>1 STUDENT ABSENT</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>

                        <div class="service-content py-3">
                            <h6 className='fw-bold'>E. SCHOOL INFRASTRUCTURE:</h6>  
                        </div>

                       
                        <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="warning">
                                    <th>S.NO.</th>
                                    <th>INFORMATION</th>
                                    <th>DETAILS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)</td>
                                    <td>11074.52</td>
                                </tr>
                                <tr class="success">
                                    <td>2</td>
                                    <td>NO. AND SIZE OF THE CLASS ROOMS (IN SQ FTMTR)</td>
                                    <td>47 &amp; 57</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQ MTR)</td>
                                    <td>5 &amp; 65</td>
                                </tr>
                                <tr class="success">
                                    <td>4</td>
                                    <td>INTERNET FACILITY (Y / N)</td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>NO. OF GIRLS TOILETS</td>
                                    <td>26</td>
                                </tr>
                                <tr class="success">
                                    <td>6</td>
                                    <td>NO. OF BOYS TOILETS</td>
                                    <td>18</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL</td>
                                    <td><a href="https://youtube.com/shorts/tWM6Cxz7NDM?feature=share" target="_blank">VIEW</a></td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
        </div>
      </section>
    </>
  )
}

export default Disclosure
