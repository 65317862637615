import React from 'react'
import { IoMdCalculator } from 'react-icons/io'
import { IoLocationOutline, IoMailOpenOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'

const FooterContainer = () => {
 
  return (
    <>
      <>
      <footer class="bg-dark pt-5">        
          <div id="footer" class="footer-dark pt-6 pb-0">
              <div class="container">
                <div class="row">                    
                    <div class="col-lg-4">                     
                      <div class="widget-content pe-lg-5">                         
                          <h4 class="h5 mb-4">About Us</h4>
                          <div class="widget-body">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <address className='d-flex gap-2'>                              
                                <span><IoLocationOutline size={'30'} className='text-danger' />  </span>           
                                <span>Vill- Bhajan Mau, Post- Dayalpur, Tehsil- Mohanlalganj, Lucknow 226301</span>
                            </address>
                            <p class="footer-info d-flex flex-wrap gap-2">                              
                                <IoMdCalculator size={'30'} className='text-danger' />      
                                +(123) 456-7890
                            </p>
                            <p class="footer-info d-flex flex-wrap gap-2">                              
                                <IoMailOpenOutline size={'30'} className='text-danger' /> 
                                support@example.com
                            </p>
                          </div>
                      </div>
                    </div>
          
                    <div class="footer-links col-md-6 col-lg-4">                      
                      <div class="widget-content">                         
                          <h4 class="h5 mb-4">About us</h4>
                          <div class="row">
                            <div class="col-sm-6">
                                <ul class="list-unstyled before-arrow">
                                  <li class="py-2"><Link>History</Link></li>
                                  <li class="py-2"><Link>About Red Rose</Link></li>                                  
                                  <li class="py-2"><Link>Why Red Rose</Link></li>
                                  <li class="py-2"><Link>Management Message</Link></li>
                                  <li class="py-2"><Link>Affiliation</Link></li>
                                  <li class="py-2"><Link>Testimonials</Link></li>
                                </ul>
                            </div>
                            <div class="col-sm-6">
                                <ul class="list-unstyled before-arrow">
                                  <li class="py-2"><Link>Admission</Link></li>
                                  <li class="py-2"><Link>General Rules</Link></li>
                                  <li class="py-2"><Link>Fee Structure</Link></li>
                                  <li class="py-2"><Link>Download Prospectus</Link></li>
                                  <li class="py-2"><Link>Planner</Link></li>
                                  <li class="py-2"><Link>Safety & Security</Link></li>                                 
                                </ul>
                            </div>
                          </div>
                      </div>
                    </div>
                  
                    <div class="footer-links col-md-6 col-lg-4">                      
                      <div class="widget-content">                         
                          <h4 class="h5 mb-4">Popular Links</h4>
                          <div class="row">
                            <div class="col-sm-6">
                                <ul class="list-unstyled before-arrow">
                                  <li class="py-2"><Link>Academics</Link></li>
                                  <li class="py-2"><Link>Curriculum</Link></li>
                                  <li class="py-2"><Link>Downloads</Link></li>
                                  <li class="py-2"><Link>Discipline</Link></li>
                                  <li class="py-2"><Link>Classroom</Link></li>
                                  <li class="py-2"><Link>Gallery</Link></li>
                                </ul>
                            </div>
                            <div class="col-sm-6">
                                <ul class="list-unstyled before-arrow">                                  
                                  <li class="py-2"><Link>Uniform</Link></li>
                                  <li class="py-2"><Link>Students</Link></li>
                                  <li class="py-2"><Link>Toppers</Link></li>                                  
                                  <li class="py-2"><Link>Career</Link></li>
                                  <li class="py-2"><Link>Contact Us</Link></li>
                                  <li class="py-2"><Link>Sitemap</Link></li>
                                </ul>
                            </div>
                          </div>
                      </div>
                    </div>                  
                </div>
              </div>
          </div>
       
          <div class="footer-dark">
              <div class="container py-4 border-top border-smooth">
                <div class="row">
                    <div class="col-12 text-center">
                      <p class="d-block my-3">© Red Rose Public School Lucknow | Powered by <Link to={'https://adventusindia.com/'} target='blank'>ATPL</Link></p>
                    </div>
                </div>
              </div>
          </div>          
        </footer>
      </>      
    </>
  )
}

export default FooterContainer
