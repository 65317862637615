import React from 'react'
import { Link } from 'react-router-dom'
import BreadcrumbContainer from '../Common/Breadcrumb'
import { FaFacebookSquare, FaInstagram, FaPhoneAlt, FaYoutube } from 'react-icons/fa'
import { BsTwitterX } from 'react-icons/bs'
import { IoIosMail } from 'react-icons/io'
import { CiLocationOn } from 'react-icons/ci'

const Contact = () => {
  return (
    <>
    <BreadcrumbContainer  title="Contact Us" />
        <section className="pt-5 pb-0" style={{ backgroundImage: `url('assets/img/bg/map.svg')` }}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-xl-6 text-center mx-auto">                    
                        <h6 class="text-primary">Contact us</h6>
                        <h1 class="mb-4">We're here to help!</h1>
                    </div>
                </div>
              
                <div class="row g-4 g-md-5 mt-0 mt-lg-3">                  
                    <div class="col-lg-4 mt-lg-0">
                        <div class="card card-body bg-primary shadow py-5 text-center h-100">                       
                        <h5 class="text-white mb-3">Customer Support</h5>
                        <ul class="list-inline mb-0">                           
                            <li class="list-item mb-3">
                                <Link class="text-white"> <CiLocationOn /> Vill- Bhajan Mau, Post- Dayalpur, Tehsil- Mohanlalganj, Lucknow 226301</Link>
                            </li>
                           
                            <li class="list-item mb-3">
                                <Link class="text-white"> <FaPhoneAlt />+91-809 090 2463 </Link>
                            </li>                           
                            <li class="list-item mb-0">
                                <Link class="text-white"> <IoIosMail />example@email.com </Link>
                            </li>
                        </ul>
                        </div>
                    </div>
                
                    <div class="col-lg-4 mt-lg-0">
                        <div class="card card-body shadow py-5 text-center h-100">
                     
                        <h5 class="mb-3">Contact Address</h5>
                        <ul class="list-inline mb-0">
                          
                            <li class="list-item mb-3 h6 fw-light">
                                <CiLocationOn /> Vill- Bhajan Mau, Post- Dayalpur, Tehsil- Mohanlalganj, Lucknow 226301
                            </li>
                           
                            <li class="list-item mb-3 h6 fw-light">
                                <Link className='text-dark'> <FaPhoneAlt /> +91-809 090 2463</Link>
                            </li>
                          
                            <li class="list-item mb-0 h6 fw-light">
                                <Link className='text-dark'> <IoIosMail />example@email.com </Link>
                            </li>
                        </ul>
                        </div>
                    </div>
                 
                    <div class="col-lg-4 mt-lg-0">
                        <div class="card card-body shadow py-5 text-center h-100">
                       
                        <h5 class="mb-3">Main Office Address</h5>
                        <ul class="list-inline mb-0">
                            
                            <li class="list-item mb-3 h6 fw-light">
                                <CiLocationOn /> Vill- Bhajan Mau, Post- Dayalpur, Tehsil- Mohanlalganj, Lucknow 226301
                            </li>
                          
                            <li class="list-item mb-3 h6 fw-light">
                                <Link  className='text-dark'> <FaPhoneAlt /> +91-809 090 2463</Link>
                            </li>
                           
                            <li class="list-item mb-0 h6 fw-light">
                                <Link className='text-dark'> <IoIosMail />example@email.com </Link>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            </section>

            <section className='pt-5 pb-5'>
                <div class="container">
                    <div class="row g-4 g-lg-0 align-items-center">
                        <div class="col-md-6 align-items-center text-center">
                           
                            <img src="assets/img/contact.svg" class="h-400px" alt="" />
                         
                            <div class="d-sm-flex align-items-center justify-content-center mt-2 mt-sm-4">
                            <h5 class="mb-0">Follow us on:</h5>
                            <ul class="list-inline mb-0 ms-sm-2">
                                <li class="list-inline-item"> <Link><FaFacebookSquare size={'20'} className='fb-color' /></Link> </li>
                                <li class="list-inline-item"> <Link><FaYoutube size={'20'} className='insta-color' /></Link> </li>
                                <li class="list-inline-item">  <Link><BsTwitterX size={'20'} className='tw-color' /></Link> </li>
                                <li class="list-inline-item"> <Link><FaInstagram size={'20'} className='youtube-color' /></Link> </li>
                            </ul>
                            </div>
                        </div>
                      
                        <div class="col-md-6">                          
                            <h2 class="mt-4 mt-md-0">Let's talk</h2>
                            <p>To request a quote or want to meet up for coffee, contact us directly or fill out the form and we will get back to you promptly</p>
                            <form>                           
                            <div class="mb-4 bg-light-input">
                                <label for="yourName" class="form-label">Your name *</label>
                                <input type="text" class="form-control form-control-lg" id="yourName" />
                            </div>
                       
                            <div class="mb-4 bg-light-input">
                                <label for="emailInput" class="form-label">Email address *</label>
                                <input type="email" class="form-control form-control-lg" id="emailInput" />
                            </div>
                          
                            <div class="mb-4 bg-light-input">
                                <label for="textareaBox" class="form-label">Message *</label>
                                <textarea class="form-control" id="textareaBox" rows="4"></textarea>
                            </div>
                         
                            <div class="d-grid">
                                <button class="btn btn-lg btn-primary mb-0" type="button">Send Message</button>
                            </div>
                            </form>
                        </div>                     
                    </div>
                </div>
                </section>


                <section>
               
                    <div class="responsive-maps">
                        <iframe class="lazy entered loaded" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14259.098346531784!2d80.9900566!3d26.6876908!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bef59df157c7b%3A0xd9fd411fff6a58d9!2sRed%20Rose%20Public%20School!5e0!3m2!1sen!2sin!4v1724083230527!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
               
            </section>
    </>
  )
}

export default Contact
