import React from 'react'
import {Col, Container, Image, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OurInfraContainer = () => {
    const almuni = [
        {id:1, smalldis: 'Class Room', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/infra/science-lab-01.png'},
        {id:2, smalldis: 'Computer Lab', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/infra/class-room-01.png'},
        {id:3, smalldis: 'Science Lab', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/infra/science-lab-01.png'},
        {id:4, smalldis: 'Maths Lab', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/infra/class-room-01.png'},
        {id:5, smalldis: 'Library', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/infra/science-lab-01.png'},
        {id:6, smalldis: 'Activity Room', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/infra/science-lab-01.png'},
        {id:7, smalldis: 'Music', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/infra/class-room-01.png'},
        {id:8, smalldis: 'Swimming Pool', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/infra/science-lab-01.png'},
   ];  
  return (
   
    <>
        <Container fluid  className='pt-4 pb-5' data-aos="fade-up">
            <Row>
                <Col>
                    <Container className="almuni-section">
                        <Row>
                            <Col>
                                <h2 className='our-topers liner-text2 mt-5'>Our Infrastructure</h2>
                            </Col>
                        </Row>
                        <Row className='pt-5'>     
                            {almuni.map(item => (<div className='col-md-3 pb-4'>
                                <div class="service-2 hover-service" data-aos="fade-up">
                                    <Image src={item.img} alt="..." />
                                    <div class="service-brief">
                                        <div class="elementor-flip-box-layer-overlay">
                                            <h4>{item.smalldis}</h4>
                                            <p>{item.dis}</p>
                                            <div class="btn-inline-button">
                                                <Link to={'#'} class="elementor-button">Read more</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-flip-box-layer-overlay-1">
                                        <h4>{item.smalldis}</h4>
                                    </div>
                                </div>					
                            </div>
                             ))}   
                        </Row> 
                        <Row className='py-4'>
                            <Col className='col-md-12'>
                                <div class="text-center mb-0 pb-0">                                       
                                    <button type="button" class="liner-button btn btn-primary">View More</button>
                                </div>                                 
                            </Col>
                        </Row>                   
                    </Container>  
                </Col>
            </Row>
        </Container>
     
    </>
  )
}


export default OurInfraContainer
