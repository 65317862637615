import React from 'react'
import { Autoplay} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Col, Container, Image, Row } from 'react-bootstrap';

export default function SmallSliderTwoSection() {
    const slider = [
        {id:1, smalldis: 'Rahul Bajaj', dis: '88%', img: '/assets/img/team2.png'},
        {id:2, smalldis: 'Rahul Bajaj', dis: '90%', img: 'https://placehold.co/101x101.png'},
        {id:3, smalldis: 'Rahul Bajaj', dis: '86%', img: '/assets/img/team2.png'},
        {id:4, smalldis: 'Rahul Bajaj', dis: '88%', img: '/assets/img/testi-thumb_1.png'},
        {id:5, smalldis: 'Rahul Bajaj', dis: '90%', img: 'https://placehold.co/103x103.png'},
        {id:6, smalldis: 'Rahul Bajaj', dis: '86%', img: '/assets/img/team2.png'},
        {id:7, smalldis: 'Rahul Bajaj', dis: '88%', img: '/assets/img/testi-thumb_1.png'},
        {id:8, smalldis: 'Rahul Bajaj', dis: '90%', img: 'https://placehold.co/103x103.png'},
        {id:9, smalldis: 'Rahul Bajaj', dis: '86%', img: '/assets/img/team2.png'},
    ];

  return (
    <>
        <section className='mt-5 small-slider-section'>
            <Row>
                <Col>
                    <h2 className='pb-3 liner-text2 text-center'>12th Result</h2>
                </Col>
            </Row>
            <Swiper breakpoints={{
                320: {
                    slidesPerView:2,
                    spaceBetween:10
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween:10
                },
                640: {
                    slidesPerView:2,
                    spaceBetween: 10              
                },
                1024: {
                    slidesPerView:3,
                    spaceBetween: 10              
                },
                1200: {
                    slidesPerView:4,
                    spaceBetween: 10              
                },
            }} effect="fade" slidesPerView={4} spaceBetween={10} loop={true} freeMode={true} autoplay={{delay:0, disableOnInteraction: false,}} speed={9000} modules={[Autoplay]}>
                {slider.map(item => (<SwiperSlide>      
                    <Container className="position-relative w-100">
                        <div className='top-rank-section text-center'>
                            <div className='pb-1'>
                                <div className='thumb-img'>
                                    <Image src={item.img} alt="Banner Image" layout="fill" objectFit="cover" /> 
                                </div>
                            </div> 
                            <div>
                                <h3  className='mb-1'>{item.smalldis}</h3>
                                <p>{item.dis}</p>
                            </div>
                        </div>                        
                    </Container>           
                </SwiperSlide>
            ))}   
        </Swiper>
        </section>
       
    </>
  )
}


