import React, { useRef } from 'react'
import BreadcrumbContainer from '../Common/Breadcrumb'
import {FaPhoneAlt} from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'
import { Link } from 'react-router-dom'

const ManagementMessage = () => {
    const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const scrollToSection = (sectionRef) => {
    const topOffset = sectionRef.current.getBoundingClientRect().top + window.scrollY - 100;
    window.scrollTo({ top: topOffset, behavior: 'smooth' });
  };
  return (
    <>
      <BreadcrumbContainer title="Management Message" />
        <section className="position-relative about-section"  data-aos="fade-up">
            <div className="container">
                <div className='row'>
                    <div className='col-md-3'>
                        <div class="sidebar">                            
                            <div class="widget">
                                <div class="widget-title">
                                    <h3>Management Message</h3>
                                </div>
                                <ul class="category-list">
                                    <li><Link onClick={() => scrollToSection(section1Ref)}>Founder</Link></li>                    
                                    <li><Link onClick={() => scrollToSection(section2Ref)}>Managing Director</Link></li>
                                    <li><Link onClick={() => scrollToSection(section3Ref)}>Chairperson Message</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-9'>
                        <div className="pe-lg-1-9 pe-xl-6 px-4 mb-4" ref={section1Ref}>                               
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div class="image mb-5 mb-md-0">
                                        <img class="w-100 rounded" src="/assets/img/team/founders-01.png" alt="team-1.jpg" />
                                    </div>
                                    <h6 className='mt-2 text-center'>Shri R.C. Mishra</h6>
                                </div>
                                <div className='col-md-9'>
                                    <h3>Founder</h3>
                                    <ul className="list-style-16 mb-2">
                                        <li>Under the auspices of the NAV CHETNA KENDRA SOCIETY, Kanpur Road Lucknow which was registered under the societies registration Act 1860 on 18th March 1993. We have started Red Rose Public Senior Secondary School at Vishnulok Colony, Kanpur Road Lucknow in the year 2003 in a spacious building.</li>
                                        <li>We got inspiration from Red Rose Senior Secondary School and started this public school, which is run by Nav Chetna Kendra Society. It is our hard work which resulted in the culmination of this school soon with in a short span of 6 years, the school got recognition from the prestigious Board namely Central Board of Secondary Education, Delhi. Today Red Rose has strength of more than 800 students.</li>
                                    </ul>
                                    <div className='d-flex flex-wrap gap-3 px-4'>                              
                                        <p><IoIosMail /> info@yoursite.com</p>
                                        <p><FaPhoneAlt /> +91-809 090 2463</p>                               
                                    </div>
                                </div>
                            </div>   
                        </div>

                        <div className="pe-lg-1-9 pe-xl-6 px-4 mb-4" ref={section2Ref}>                                
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div class="image mb-5 mb-md-0">
                                        <img class="w-100 rounded" src="/assets/img/team/md.png" alt="team-1.jpg" />
                                    </div>
                                    <h6 className='mt-2 text-center'>Dr. Prashant Kumar Mishra</h6>
                                </div>
                                <div className='col-md-9'>
                                    <h3>Managing Director</h3>
                                    <ul className="list-style-16 mb-2">
                                        <li>Speedy growth and elevation of the school is the outcome of the trust and confidence, reposed in us by the parents, with regard to quality education, being imparted to their wards. Here I will like to mention some salient distinguishable features of our school as below</li>
                                        <li>School-Based- Evaluation Certificate is issued to students of class X as a mandatory provision prescribed by CBSE. This provision is being applied to the students of all other classes as it is beneficial in evaluating their progress academically as well as in co-scholastic activities. The students are, therefore, required to remain regular, punctual and sincere towards their studies, during the entire session.</li>
                                        <li>With regard to English language, keeping in view its utility in competitions and in every other sphere, our English teachers adopt effective measures in teaching the English-subject, so as to bring fluency and self expression in speaking and writing of this language even to Hindi medium students.</li>
                                        <li>Computer Science is a compulsory subject for the students of class II to class X. Kids of Nursery and K.G. classes and even children of classes 1st to 4th need special care and affection so as to develop their innate qualities and talent through play-way and practical techniques, in a jovial environment. Montessori trained and primary teachers of proven ability and pleasing temperament deal with these children, creating in them a keen curiosity and desire to learn and behave properly.</li>
                                        <li>The Managing Director, himself gives special attention to each and every student, whose class and home-work copies are thoroughly checked each month in order to evaluate the progress in each subject and raise the standard to the desired level of competency. Extra and remedial classes, absolutely free of any charge are also conducted by our own teachers to help the students to make up their deficiencies. We adopt a liberal view in granting admission to candidates unless there are congent reasons to refuse it. We never allow our students to appear as private candidates in the Board Examinations. Result of our students in the Board examination of class X and class XII has always been quite satisfactory over 90% success.</li>
                                        <li>Last but not the least, we seek co-operation and confidence of the parents which inspires us to impart excellent education to their wards and infuse moral values and high sense of discipline in them.</li>
                                    </ul>
                                    <div className='d-flex flex-wrap gap-3 px-4'>                              
                                        <p><IoIosMail /> info@yoursite.com</p>
                                        <p><FaPhoneAlt /> +91-809 090 2463</p>                               
                                    </div>
                                </div>
                            </div>   
                        </div>

                        <div className="pe-lg-1-9 pe-xl-6 px-4 mb-4" ref={section3Ref}>                                
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div class="image mb-5 mb-md-0">
                                        <img class="w-100 rounded" src="/assets/img/team/president.png" alt="team-1.jpg" />
                                    </div>
                                    <h6 className='mt-2 text-center'>Smt. Smita Mishra</h6>
                                </div>
                                <div className='col-md-9'>
                                    <h3>Chairperson Message</h3>
                                    <ul className="list-style-16 mb-2">
                                        <li>Speedy growth and elevation of the school is the outcome of the trust and confidence, reposed in us by the parents, with regard to quality education, being imparted to their wards. Here I will like to mention some salient distinguishable features of our school as below</li>
                                        <li>School-Based- Evaluation Certificate is issued to students of class X as a mandatory provision prescribed by CBSE. This provision is being applied to the students of all other classes as it is beneficial in evaluating their progress academically as well as in co-scholastic activities. The students are, therefore, required to remain regular, punctual and sincere towards their studies, during the entire session.</li>
                                        <li>With regard to English language, keeping in view its utility in competitions and in every other sphere, our English teachers adopt effective measures in teaching the English-subject, so as to bring fluency and self expression in speaking and writing of this language even to Hindi medium students.</li>
                                        <li>Computer Science is a compulsory subject for the students of class II to class X. Kids of Nursery and K.G. classes and even children of classes 1st to 4th need special care and affection so as to develop their innate qualities and talent through play-way and practical techniques, in a jovial environment. Montessori trained and primary teachers of proven ability and pleasing temperament deal with these children, creating in them a keen curiosity and desire to learn and behave properly.</li>
                                        <li>The chairperson message, himself gives special attention to each and every student, whose class and home-work copies are thoroughly checked each month in order to evaluate the progress in each subject and raise the standard to the desired level of competency. Extra and remedial classes, absolutely free of any charge are also conducted by our own teachers to help the students to make up their deficiencies. We adopt a liberal view in granting admission to candidates unless there are congent reasons to refuse it. We never allow our students to appear as private candidates in the Board Examinations. Result of our students in the Board examination of class X and class XII has always been quite satisfactory over 90% success.</li>
                                        <li>Last but not the least, we seek co-operation and confidence of the parents which inspires us to impart excellent education to their wards and infuse moral values and high sense of discipline in them.</li>
                                    </ul>
                                    <div className='d-flex flex-wrap gap-3 px-4'>                              
                                        <p><IoIosMail /> info@yoursite.com</p>
                                        <p><FaPhoneAlt /> +91-809 090 2463</p>                               
                                    </div>
                                </div>
                            </div>   
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ManagementMessage
