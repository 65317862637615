import React, { useEffect} from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import HeaderContainer from './Common/Header';
import FooterContainer from './Common/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Layout() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({ duration: 500, easing: 'ease', once: false });
  }, [])

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'instant'});
  }, [location.pathname])

  return (
   
   <>
        <HeaderContainer />
            <main id='main'>
                <Outlet /> 
            </main>
        <FooterContainer />
   </>
  )
}

export default Layout