import React from 'react'
import BreadcrumbContainer from '../../Common/Breadcrumb'

const About = () => {
  return (
    <>
      <BreadcrumbContainer title="About" />

      <section className="position-relative about-section"  data-aos="fade-up">
          <div className="container">
              <div className="bg-container"  style={{backgroundImage:'url("/assets/img/bg-pattern.png")'}}></div>
              
              <div className="row align-items-center z-index-1 position-relative">
                <div className="col-lg-6 mb-1-9 mb-lg-0">
                    <div className="pe-lg-1-9 pe-xl-6">
                      <div className="section-heading half left">
                          <h2>About Red Rose</h2>
                      </div>
                      <h4 className="pt-4 pb-2">School Vision</h4>
                      <ul className="list-style-16">
                          <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                          <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                      </ul>
                      <h4 className="pt-4 pb-2">School Mission</h4>
                      <ul className="list-style-16">
                          <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                          <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                          <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                      </ul>
                    </div>
                </div>
                <div className="col-lg-6 col-md-9 text-center">
                    <div className="row g-2">
                      <div className="col-6">
                          <img className="border-radius-5" alt="..." src="/assets/img/team-02.jpg" />
                      </div>
                      <div className="col-6">
                          <img className="border-radius-5" alt="..." src="/assets/img/team-01.jpg" />
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </section>

        <section className="parallax p-0" data-overlay-dark="0" style={{backgroundImage:'url("/assets/img/about-02.jpg")'}}>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-lg-6 offset-lg-6">
                        <div className="about-text about-overlay">
                            <div className="w-xl-85">
                                <div className="section-heading left white"><span>about us</span>
                                    <h4>Welcome to our consulting</h4>
                                </div>
                                <div className="inner-content">
                                    <h2><span>“ Lorem Ipsum is simply dummy text of the printing and typesetting industry." </span> for Boys &amp; private clients.</h2>
                                    <p className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default About
