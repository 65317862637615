import React from 'react'
import HeroSliderContainer from '../../Common/HeroSlider'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import SmallSliderOneSection from '../../Component/SmallSliderOne'
import SmallSliderTwoSection from '../../Component/SmallSliderTwo'
import ManagementContainer from '../../Component/Management'
import AboutContainer from '../../Component/AboutSection'
import GalleryContainer from '../../Component/GallerySection'
import SocialMediaContainer from '../../Component/SocialMedia'
import TestimonialContainer from '../../Component/TestimonialScetion'
import AlumniContainer from '../../Component/AlumniSection'
import OurInfraContainer from '../../Component/OurInfrastructure'

import OurBranchContainer from '../../Component/OurBranch'
import { ConatctContainer } from '../../Component/ContactSection'
import Counter from '../../Common/Counter'

const Home = () => {
    
  return (
    <>
        {/* ****************hero slider*********** */}
        <HeroSliderContainer />    

        {/* ****************toper slider*********** */}
        <Container fluid  className='pt-4 pb-5 bg-dark-grey position-relative' data-aos="fade-up" style={{backgroundImage:'url("/assets/img/bg-pattern.png")'}}>
            <Row>
                <Col>
                    <Container>
                        <div className='absolute-winner-01'><Image src="/assets/img/bg/bg-winner-01.png" /> </div>
                        <div className='absolute-winner-02'><Image src="/assets/img/bg/bg-winner-01.png" /> </div>
                        <Row>
                            <Col>
                                <h2 className='our-topers liner-text2 mt-5 mb-0'>Our Toppers</h2>
                                <div className='relative-ribbon-03 text-center'><Image src="/assets/img/bg/ribon-01.png" /> </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className='col-md-6'>
                                <SmallSliderOneSection />
                            </div>
                            <div className='col-md-6'>
                                <SmallSliderTwoSection />
                            </div>
                        </Row>
                        <Row>
                            <div>
                                <div className='d-flex justify-content-end mt-4'>
                                    <Button className='liner-button'>Read More</Button>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>   

        {/* ****************About*********** */} 
        <AboutContainer />

        {/* ****************manegement slider*********** */} 
        <ManagementContainer />
        <SocialMediaContainer />        
        <GalleryContainer />
        <TestimonialContainer />
        <AlumniContainer />
        <OurInfraContainer />
        <Counter />
        <OurBranchContainer />
        
        <ConatctContainer />        

        
    </>
  )
}

export default Home
