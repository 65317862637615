import React from 'react'
import {Col, Container, Row } from 'react-bootstrap'

const SocialMediaContainer = () => {
  return (
    <>
    <Container fluid  className='pt-4 mt-5 gallery-section' data-aos="fade-up">
            <Row>
                <Col>
                    <h2 className='our-topers liner-text2 mt-2'>Social Media Updates</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <section className='mt-5 managements-section container'>                        
                        <div className='row'>
                            <div className='col-md-4'>
                                <h2 className='pb-3 liner-text2 f-20 font-weight-bold'>Facebook</h2>
                                <div className='social-content'>
                                    <div>
                                        <iframe className="fac_ifrma" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmgip391%2F%3Fref%3Dpy_c&tabs=timeline&width=340&height=400px&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" ></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <h2 className='pb-3 liner-text2 f-20 font-weight-bold'>Twitter</h2>
                                <div className='social-content'>
                                    <div>
                                        <iframe className="fac_ifrma" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmgip391%2F%3Fref%3Dpy_c&tabs=timeline&width=340&height=400px&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" ></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <h2 className='pb-3 liner-text2 f-20 font-weight-bold'>Instagram</h2>
                                <div className='social-content'>
                                    <div>
                                        <iframe className="fac_ifrma" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmgip391%2F%3Fref%3Dpy_c&tabs=timeline&width=340&height=400px&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default SocialMediaContainer
