import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import BreadcrumbContainer from '../Common/Breadcrumb';

const SwimmingPool = () => {
    const images = [
        '/assets/img/portfolio.jpg',
        '/assets/img/portfolio.jpg',
        '/assets/img/portfolio.jpg',
        '/assets/img/portfolio.jpg',
        '/assets/img/portfolio.jpg',
        '/assets/img/portfolio.jpg',
        '/assets/img/portfolio.jpg',
        '/assets/img/portfolio.jpg'        
       ];
    
      const [isOpen, setIsOpen] = useState(false);
      const [photoIndex, setPhotoIndex] = useState(0);
      const [loading, setLoading] = useState(false);
    
      const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
        setLoading(true);
      };
    
      const closeLightbox = () => {
        setIsOpen(false);
        setLoading(false);
      };
    
      const onImageLoad = () => {
        setLoading(false);
      };
  return (
    <>
    <BreadcrumbContainer title="Swimming Pool" />
        <Container fluid  className='pt-5 pb-5 gallery-section bg-dark-grey' data-aos="fade-up">           
            <Row>
                <Col>
                    <Container className='pt-5'>
                        <div class="row text-center gallery text-center">                            
                            {images.map((image, index) => (
                                <div key={index} className="col-md-3 mb-4">
                                    <div className='gallery-block'>
                                        <img src={image} alt={`Image ${index + 1}`} onClick={() => openLightbox(index)} style={{cursor: 'pointer' }} />
                                    </div>
                                </div>
                                ))}
                                {isOpen && (
                                    <Lightbox
                                        mainSrc={images[photoIndex]}
                                        nextSrc={images[(photoIndex + 1) % images.length]}
                                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                        onCloseRequest={closeLightbox}
                                        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                                        imageLoadErrorHandler={() => setLoading(false)}
                                        onImageLoad={onImageLoad}
                                    />
                                )}
                                {loading && <div className="loading-indicator">Loading...</div>}                                
                            </div>                           
                    </Container>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default SwimmingPool

