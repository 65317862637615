import React from 'react'
import {Autoplay, Navigation, Pagination} from 'swiper/modules'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Image } from 'react-bootstrap'
import { IoIosStar } from 'react-icons/io'

const ParentsContainer = () => {
    const slider2 = [
        {id:1, smalldis: 'Rahul Bajaj', disig: 'Markets', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/testi-thumb_1.png'},
        {id:2, smalldis: 'Rahul Bajaj', disig: 'Markets', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/testi-thumb_1.png'},
        {id:3, smalldis: 'Rahul Bajaj', disig: 'Markets', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/testi-thumb_1.png'},
    ];

  return (
    <>
        <section>              
            <h2 className='pb-3 liner-text3 f-20 font-weight-bold text-white'>Parent's Review</h2>                          
            <Swiper spaceBetween={20} slidesPerView={1} effect="fade" loop={true} pagination={{ clickable: true }} autoplay={{delay:5000, disableOnInteraction: false,}} modules={[Autoplay, Navigation, Pagination]}>
                {slider2.map(item => (<SwiperSlide> <blockquote className="reviews-one rounded-3 mb-5">
                    <ul className="ratings my-2 text-muted">
                        <li className="active"><IoIosStar /></li> 
                        <li className="active"><IoIosStar /></li> 
                        <li className="active"><IoIosStar /></li> 
                        <li className="active"><IoIosStar /></li> 
                        <li className="active"><IoIosStar /></li>                                        
                    </ul>
                    <p className='mb-3'>{item.dis}</p>
                    
                    <div className="d-flex">
                        <Image className="d-flex align-self-center rounded-circle reviews-one-thumb shadow-md mx-3 mt-2" src={item.img} alt="Image description" />
                        <div className="align-self-center">
                            <p className="mb-0">
                                <strong className="d-block">{item.smalldis}</strong>
                                <span className="text-muted fs-75">{item.disig}</span>
                            </p>
                        </div>
                    </div> 
                </blockquote>
                
                </SwiperSlide> 
                ))}     
            </Swiper>                         
        </section>                      
    </>
  )
}

export default ParentsContainer


