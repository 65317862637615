import React from 'react'
import { Link } from 'react-router-dom'
import BreadcrumbContainer from '../Common/Breadcrumb'

const Infrastructure = () => {
  return (
    <>
        <BreadcrumbContainer  title="Infrastructure" />
      <section class="pt-5">
            <div class="container">
                <div class="row g-4 justify-content-center mb-5">  
                    <div class="col-lg-10 col-xl-6">
                        <div class="card shadow p-2">
                            <div class="row g-0">                           
                                <div class="col-md-4">
                                    <img src="assets/img/team/04.jpg" class="rounded-3" alt="..." />
                                </div>                           
                                <div class="col-md-8">
                                    <div class="card-body">                                   
                                        <div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                                            <div>
                                                <h5 class="card-title mb-0"><Link>Dennis Barrett</Link></h5>
                                                <p class="small mb-2 mb-sm-0">Professor at Sigma College</p>
                                            </div>                                  
                                        </div>
                                    
                                        <p class="text-truncate-2 mb-3">Perceived end knowledge certainly day sweetness why cordially. Ask a quick six seven offer see among.</p>
                                        <div class="d-sm-flex justify-content-sm-between align-items-center">                                   
                                            <h6 class="text-orange mb-0">Digital Marketing</h6>                                   
                                            <ul class="list-inline mb-0 mt-3 mt-sm-0">
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-facebook" href="#"><i class="fab fa-fw fa-facebook-f"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-instagram-gradient" href="#"><i class="fab fa-fw fa-instagram"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-twitter" href="#"><i class="fab fa-fw fa-twitter"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 text-linkedin" href="#"><i class="fab fa-fw fa-linkedin-in"></i></Link> 
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                 
                    <div class="col-lg-10 col-xl-6">
                        <div class="card shadow p-2">
                            <div class="row g-0">                           
                                <div class="col-md-4">
                                    <img src="assets/img/team/04.jpg" class="rounded-3" alt="..." />
                                </div>                           
                                <div class="col-md-8">
                                    <div class="card-body">                                   
                                        <div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                                            <div>
                                                <h5 class="card-title mb-0"><Link>Dennis Barrett</Link></h5>
                                                <p class="small mb-2 mb-sm-0">Professor at Sigma College</p>
                                            </div>
                                        </div>
                                    
                                        <p class="text-truncate-2 mb-3">Perceived end knowledge certainly day sweetness why cordially. Ask a quick six seven offer see among.</p>
                                        <div class="d-sm-flex justify-content-sm-between align-items-center">                                   
                                            <h6 class="text-orange mb-0">Digital Marketing</h6>                                   
                                            <ul class="list-inline mb-0 mt-3 mt-sm-0">
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-facebook" href="#"><i class="fab fa-fw fa-facebook-f"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-instagram-gradient" href="#"><i class="fab fa-fw fa-instagram"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-twitter" href="#"><i class="fab fa-fw fa-twitter"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 text-linkedin" href="#"><i class="fab fa-fw fa-linkedin-in"></i></Link> 
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>     

                    <div class="col-lg-10 col-xl-6">
                        <div class="card shadow p-2">
                            <div class="row g-0">                           
                                <div class="col-md-4">
                                    <img src="assets/img/team/04.jpg" class="rounded-3" alt="..." />
                                </div>                           
                                <div class="col-md-8">
                                    <div class="card-body">                                   
                                        <div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                                            <div>
                                                <h5 class="card-title mb-0"><Link>Dennis Barrett</Link></h5>
                                                <p class="small mb-2 mb-sm-0">Professor at Sigma College</p>
                                            </div>                                  
                                        </div>
                                    
                                        <p class="text-truncate-2 mb-3">Perceived end knowledge certainly day sweetness why cordially. Ask a quick six seven offer see among.</p>
                                        <div class="d-sm-flex justify-content-sm-between align-items-center">                                   
                                            <h6 class="text-orange mb-0">Digital Marketing</h6>                                   
                                            <ul class="list-inline mb-0 mt-3 mt-sm-0">
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-facebook" href="#"><i class="fab fa-fw fa-facebook-f"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-instagram-gradient" href="#"><i class="fab fa-fw fa-instagram"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-twitter" href="#"><i class="fab fa-fw fa-twitter"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 text-linkedin" href="#"><i class="fab fa-fw fa-linkedin-in"></i></Link> 
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div class="col-lg-10 col-xl-6">
                        <div class="card shadow p-2">
                            <div class="row g-0">                           
                                <div class="col-md-4">
                                    <img src="assets/img/team/04.jpg" class="rounded-3" alt="..." />
                                </div>                           
                                <div class="col-md-8">
                                    <div class="card-body">                                   
                                        <div class="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                                            <div>
                                                <h5 class="card-title mb-0"><Link>Dennis Barrett</Link></h5>
                                                <p class="small mb-2 mb-sm-0">Professor at Sigma College</p>
                                            </div>                                  
                                        </div>
                                    
                                        <p class="text-truncate-2 mb-3">Perceived end knowledge certainly day sweetness why cordially. Ask a quick six seven offer see among.</p>
                                        <div class="d-sm-flex justify-content-sm-between align-items-center">                                   
                                            <h6 class="text-orange mb-0">Digital Marketing</h6>                                   
                                            <ul class="list-inline mb-0 mt-3 mt-sm-0">
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-facebook" href="#"><i class="fab fa-fw fa-facebook-f"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-instagram-gradient" href="#"><i class="fab fa-fw fa-instagram"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 me-1 text-twitter" href="#"><i class="fab fa-fw fa-twitter"></i></Link> 
                                                </li>
                                                <li class="list-inline-item"> 
                                                    <Link class="mb-0 text-linkedin" href="#"><i class="fab fa-fw fa-linkedin-in"></i></Link> 
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>              
                </div>               
            </div>
            </section>
    </>
  )
}

export default Infrastructure
