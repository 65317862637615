import React from 'react'
import {Col, Container, Image, Row} from 'react-bootstrap';

const AlumniContainer = () => {
    const almuni = [
        {id:1, smalldis: 'Rahul Bajaj', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/team2.png'},
        {id:2, smalldis: 'Rahul Bajaj', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/team2.png'},
        {id:3, smalldis: 'Rahul Bajaj', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/team2.png'},
        {id:4, smalldis: 'Rahul Bajaj', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/team2.png'},
        {id:5, smalldis: 'Rahul Bajaj', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/team2.png'},
        {id:6, smalldis: 'Rahul Bajaj', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/team2.png'},
        {id:7, smalldis: 'Rahul Bajaj', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/team2.png'},
        {id:8, smalldis: 'Rahul Bajaj', dis: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', img: '/assets/img/team2.png'},
   ];  
  return (
   
    <>
        <Container fluid  className='pt-4 pb-5 bg-dark-grey' data-aos="fade-up">
            <Row>
                <Col>
                    <Container className="almuni-section">
                        <Row>
                            <Col>
                                <h2 className='our-topers liner-text2 mt-5'>Alumni</h2>
                            </Col>
                        </Row>
                        <Row className='pt-5'>
                            {almuni.map(item => (<div className='col-md-3 pb-4'>
                                <div class="card rounded aos-init aos-animate" data-aos="fade-up">
                                    <Image src={item.img} class="img-fluid rounded-top" alt="" />
                                    <div class="bg-white rounded-bottom p-3 text-center">
                                        <h4 class="title fw-bold text-dark text-center mb-0">Portfolio</h4>
                                        <p className='mb-1'>Project Manager</p>
                                        {/* <div className='d-flex position-relative justify-content-center text-dark social-icons'>
                                            <ListGroup.Item><Link to={'#'}><FaFacebookSquare size={'16'} className='fb-color' /></Link></ListGroup.Item>                               
                                            <ListGroup.Item><Link to={'#'}><BsTwitterX size={'16'} className='tw-color' /></Link></ListGroup.Item>     
                                            <ListGroup.Item><Link to={'#'}><FaYoutube size={'16'} className='insta-color' /></Link></ListGroup.Item>   
                                            <ListGroup.Item><Link to={'#'}><FaInstagram size={'16'} className='youtube-color' /></Link></ListGroup.Item>   
                                        </div> */}
                                    </div>
                                </div>  
                            </div> 
                            ))}   
                        </Row>                                        
                    </Container>  
                </Col>
            </Row>
        </Container>
     
    </>
  )
}

export default AlumniContainer
